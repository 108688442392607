<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	//定义一个函数判断是手机端还是pc端
	function isMobile() {
	  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
	    return true; // 移动端
	  } else {
	    return false; // PC端
	  }
	}
	
	export default{
		mounted() {
			let that = this;
			//判断后的操作
			if (isMobile()) {
				that.$router.push("/index")
				// 判断true跳转到这个主页
			} else {
				that.$router.push("/web/index/index")
				// 判断false跳转到这个主页
			}
		}
	}
</script>
