import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 多语言
import VueI18n from 'vue-i18n'
// 导入公共样式库文件
import './assets/css/public.css'

// 导入样式重置文件
import './assets/css/reset.css'
// 导入全局样式
import './assets/css/global.css'

// 图标
import './assets/font/iconfont.css'

// 导入并配置axios
import axios from 'axios'

// 导入vant框架
import Vant from 'vant'
import 'vant/lib/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//定义一个函数判断是手机端还是pc端
function isMobile() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true; // 移动端
  } else {
    return false; // PC端
  }
}
// import Language from './lang/lang.json'
// 修改代理，四个websocket地址[index,trade/index,trade/contact,trade/contact_list]
axios.defaults.baseURL = 'https://xz0007.com'
// axios.defaults.baseURL = 'http://192.168.4.160:8027'
//axios.defaults.baseURL = 'http://124.220.165.95:83'
//axios.defaults.baseURL = '/api' 
// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('token')
  return config
})
// 响应拦截
axios.interceptors.response.use(response => {
  if (response.data.code === 401) {
    window.localStorage.removeItem('token')
    // 判断后的操作
    if (isMobile()) {
      router.push('/login')
      // 判断true跳转到这个主页
    } else {
      router.push('/web/index/login')
      // 判断false跳转到这个主页
    }

    return false
  } else {
    return response
  }
})
// 将axios挂载为全局方法$http
Vue.prototype.$http = axios
Vue.prototype.wsurl = 'wss://xz0007.com'
// Vue.prototype.wsurl = 'wss://192.168.4.160:8027'
// 全局函数
Vue.prototype.getlang = (msg, lang) => {
  lang = lang || 'en'

  // //let data = require(`/lang/${lang}.json`)
  // async function langExchange() {
  //   let data = await axios.get(`/lang/${lang}.json`)
  //   return data;
  // }
  // let langData = langExchange()
  // //  async data => await axios.get(`/lang/${lang}.json`).then(res => {
  // // return res
  // //  })
  // return langData[msg]
  let data = require(`./lang/${lang}.json`)
  console.log(msg)
  return data[msg]
}
// 全局时间过滤器
Vue.filter('dateformat', function (time) {
  const dt = new Date(parseInt(time) * 1000)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
// 多语言
Vue.use(VueI18n)

// vant框架
Vue.use(Vant)
Vue.config.productionTip = false;
//process.env.NODE_ENV === "development"
//判读环境是生产环境，还是开发环境，如果是开发环境就使用src中的lang文件会在构建内，如果是生产环境就用项目根目录的lang文件（不会在构建内）
if (true) {
  const zh = require('./lang/zh.json')
  const en = require('./lang/en.json')
  const ru = require('./lang/ru.json')
  const es = require('./lang/es.json')
  const fr = require('./lang/fr.json')
  const it = require('./lang/it.json')
  const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en',
    messages: {
      zh: {
        ...zh
      }, // 阿拉伯
      en: {
        ...en
      }, // 英文语言包
      ru: {
        ...ru // 翻译
      },
      it: {
        ...it // 越南
      },
      fr: {
        ...fr //日语
      },
      es: {
        ...es // 西班牙
      }
    }
  })
  // Vue.prototype.$Local = Locals
  new Vue({
    i18n,
    router,
    render: h => h(App)
  }).$mount('#app')
} else {
  let header = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8'
  }

  async function fetchMessage() {

    const zh = await axios.get('/lang/zh.json', { headers: header })
    const en = await axios.get('/lang/en.json', { headers: header })
    const ru = await axios.get('/lang/ru.json', { headers: header })
    const fr = await axios.get('/lang/fr.json', { headers: header })
    const it = await axios.get('/lang/it.json', { headers: header })
    const es = await axios.get('/lang/es.json', { headers: header })

    return {
      zh: {
        ...zh.data
      }, // 中文语言包
      en: {
        ...en.data
      }, // 英文语言包
      ru: {
        ...ru.data
      },
      it: {
        ...it.data
      },
      fr: {
        ...fr.data
      },
      es: {
        ...es.data
      }
    }
  }

  async function bootstrap() {
    const i18n = new VueI18n({
      locale: localStorage.getItem('lang') || 'en',
      messages: await fetchMessage()
    })
    new Vue({
      i18n,
      router,
      render: h => h(App)
    }).$mount('#app')
  }

  bootstrap()
}



